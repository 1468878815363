import React from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import { Seo, Text } from 'components';
import Header from 'components/reviews/Header';
import Footer from 'components/reviews/Footer';

const Privacy = () => (
  <>
    <Seo />
    <MainContainer>
      <Header isMobileApp={false} />
    </MainContainer>
    <Container>
      <Wrapper>
        <StyledTitle>Privacy Policy</StyledTitle>
        <StyledText>
          In this privacy policy we, UAB &quot;Medical Score`&quot;, company
          code 305445861 , with our registered office at J. Žemgulio g. 46,
          LT-35239 Panevėžys, the Republic of Lithuania (hereinafter referred to
          as “Company”, “We”, “Our”, or “Us”), explain how we handle your
          personal data when you visit our website, contact us through our
          official social media pages or email, and/or use our services. In this
          notice you will find the answers to the following questions:
        </StyledText>
        <StyledList>
          <StyledListElement>how we use your data;</StyledListElement>
          <StyledListElement>
            when we provide your data to others;
          </StyledListElement>
          <StyledListElement>how long we store your data;</StyledListElement>
          <StyledListElement>what is our marketing policy;</StyledListElement>
          <StyledListElement>
            what rights related to personal data you possess;
          </StyledListElement>
          <StyledListElement>how we use cookies;</StyledListElement>
          <StyledListElement>
            other issues that you should take into account.
          </StyledListElement>
        </StyledList>
        {/* Section 1 */}
        <StyledHeading>1. HOW WE USE YOUR PERSONAL DATA?</StyledHeading>
        <StyledText>
          This Section provides the following information:
        </StyledText>
        <StyledList>
          <StyledListElement>
            categories of personal data, that we process;
          </StyledListElement>
          <StyledListElement>
            in case of the personal data that we did not obtain directly from
            you, the source and specific categories of that data;
          </StyledListElement>
          <StyledListElement>
            the purposes for which we may process your personal data; and
          </StyledListElement>
          <StyledListElement>
            the legal bases of the processing.
          </StyledListElement>
        </StyledList>
        <StyledText>
          1.2 We process your account data (“account data”). The account data
          may include your name and email address, phone number and other data
          that you provide while registering as well as your purchase history.
          We obtain such data directly from you. We process account data for the
          purposes of operating our website, providing our services, ensuring
          the security of our website and services and communicating with you.
          The legal basis for this processing is the performance of a contract
          between you and us and/or taking steps, at your request, to enter into
          such a contract as well as our legitimate interest, namely monitoring
          and improving our website and services.
        </StyledText>
        <StyledText>
          1.3. We process information relating to provision of services by us to
          you (“service data”). The service data may include your contact
          details (such as your email address), bank account and transaction
          details as well as other information that you provide to us while
          filling up the relevant questionnaires (such may include sensitive
          personal data, related to your health, in case such data is necessary
          to provide the relevant service). The service data is processed to
          provide services as well as keep proper records of those transactions.
          The legal basis for this processing is the performance of a contract
          between you and us and/or taking steps, at your request, to enter into
          such a contract and our legitimate interests, namely the proper
          administration of our website and business. In case of sensitive
          personal data, related to your health the legal basis for processing
          is your explicit consent.
        </StyledText>
        <StyledText>
          1.4. We may process information that you provide to us for the purpose
          of subscribing to our email messages and newsletters (“messaging
          data”). The messaging data is processed to send you the relevant
          messages and newsletters. The legal basis for this processing is your
          consent. Also, if we have already sold goods or provided services for
          you via our website, and you do not object, we may also process
          messaging data on the basis of our legitimate interest, namely seeking
          maintain and improve customer relations.
        </StyledText>
        <StyledText>
          1.5. We may process information relating to any communication that you
          send to us (“correspondence data”). The correspondence data may
          include the communication content and metadata associated with the
          communication. The correspondence data is processed for the purposes
          of communicating with you and record-keeping. The legal basis for this
          processing is our legitimate interests, namely the proper
          administration of our website and business, ensuring uniform and high
          quality consultation practice and for investigating disputes between
          you and our employees.
        </StyledText>
        <StyledText>
          1.6. We may process information on your use of our website as well as
          on your device (“device data”) when you are browsing our website.
          Device data may include IP address, geographical location, browser
          type and version, operating system, device type, screen resolution and
          (in case you agree to share such) your location data as well as
          information on the motion activity, use of our website (i.e. referral
          source, length of visit, page views and website navigation paths, as
          well as information about the timing, frequency and pattern of your
          service use). We obtain such data through the use of cookies and
          similar technologies. Device data is processed to enhance the website
          as well as to set default options. We also use such data to have a
          better understanding of how you use our website and services as well
          as for securing the website. The legal basis for this processing is
          our legitimate interest, namely the proper management of our website
          and business.
        </StyledText>
        <StyledText>
          1.7. We may process any of your personal data identified in this
          notice where necessary for the establishment, exercise or defence of
          legal claims, whether in court proceedings or in an administrative or
          out-of-court procedure. The legal basis for this processing is our
          legitimate interests, namely the protection and assertion of our legal
          rights, your legal rights and the legal rights of others.
        </StyledText>
        <StyledText>
          1.8. We may process any of your personal data identified in this
          notice where necessary for the purposes of obtaining or maintaining
          insurance coverage, managing risks, or obtaining professional advice.
          The legal basis for this processing is our legitimate interests,
          namely the proper protection of our business against risks.
        </StyledText>
        <StyledText>
          1.9. In addition to the specific purposes for which we may process
          your personal data set out in this Section, we may also process any of
          your personal data where such processing is necessary for compliance
          with a legal obligation to which we are subject, or in order to
          protect your vital interests or the vital interests of another natural
          person.
        </StyledText>
        <StyledText>
          1.10. Should the purpose or legal basis of data processing activities
          indicated in this paragraph change, we will inform you and, if the
          consent was the legal basis for data processing, will re-obtain your
          consent.
        </StyledText>
        <StyledText>
          1.11. Sometimes we may aggregate, anonymize or de-identify your
          personal data in such a way so that it cannot reasonably be used to
          identify you. Such data is no longer personal. We may use such data
          without restriction in any way allowed by law, including, but not
          limited to share such data with our partners or research institutions,
          share in articles, blog posts and scientific publications, aggregate
          statistics about certain activities or symptoms from data collected to
          help identify patterns across users and evaluate or modify our
          services.
        </StyledText>
        <StyledText>
          1.12. We are following the principle of data minimisation: personal
          data processed is adequate, relevant and limited to what is necessary
          in relation to the purposes for which it is processed.
        </StyledText>
        <StyledText>
          1.13. Personal data is stored either on the servers of the Company or
          of our contractors, who are bound by specific contractual clauses
          regarding the processing of personal data as well as by the
          confidentiality obligations.
        </StyledText>
        <StyledText>
          1.14. We are using a number of technical and organisational means to
          protect your personal data. Organisational security measures include
          restricting access solely to authorised persons with a legitimate need
          to access personal data, singing confidentiality agreements, arranging
          trainings, creating and implementing relevant policies and procedures.
          Technical measures include appropriate actions to address online
          security, risk of data loss, alteration of data or unauthorised
          access, implementing access control and authentication tools, ensuring
          physical security etc.
        </StyledText>
        {/* Insert more sections as needed */}
        <StyledHeading>2. WHEN WE PROVIDE YOUR DATA TO OTHERS?</StyledHeading>
        <StyledText>
          2.1 We may disclose your personal data to any member of our group of
          companies (including our subsidiaries, our ultimate holding company
          and all its subsidiaries) insofar as reasonably necessary for the
          purposes set out in this notice.
        </StyledText>
        <StyledText>
          2.1.1. Your personal data may be disclosed to and processed by our
          company UAB &quot;Medical Score&quot; company code 305445861, with our
          registered office at J. Žemgulio g. 46, LT-35239 Panevėžys, the
          Republic of Lithuania email: hello@nova.health insofar as reasonably
          necessary for the purposes of collecting, processing, and
          administrating payments for the services.
        </StyledText>
        <StyledText>
          2.2. We may disclose your personal data to our insurers and/or
          professional advisers insofar as reasonably necessary for the purposes
          of obtaining or maintaining insurance coverage, managing risks,
          obtaining professional advice, or the establishment, exercise or
          defence of legal claims, whether in court proceedings or in an
          administrative or out-of-court procedure.
        </StyledText>
        <StyledText>
          2.3. We may disclose your personal data to our anti-fraud, risks and
          compliance providers insofar as reasonably necessary for the purposes
          of protecting your personal data and fulfilling our legal obligations.
        </StyledText>
        <StyledText>
          2.4. We may disclose your personal data to our payment service
          providers. We will share service data with our payment services
          providers only to the extent necessary for the purposes of processing
          your payments, transferring funds and dealing with complaints and
          queries relating to such payments and transfers.
        </StyledText>
        <StyledText>
          2.5. We may disclose your personal data to other service providers
          insofar as it is reasonably necessary to provide specific services
          (including, providers of servers and maintenance thereof, email
          service providers, service providers used for data analysis or
          marketing, call centres, customer satisfaction surveys or market
          research). We take all the necessary measures to ensure that such
          subcontractors would implement proper organisational and technical
          measures to ensure security and privacy of your personal data.
        </StyledText>
        <StyledText>
          2.6. In addition to the specific disclosures of personal data set out
          in this Section, we may disclose your personal data where such
          disclosure is necessary for compliance with a legal obligation to
          which we are subject, or in order to protect your vital interests or
          the vital interests of another natural person.
        </StyledText>
        <StyledText>
          2.7. Persons, indicated in this Section may be established outside the
          Republic of Lithuania, European Union and European Economic Area. In
          case we will transfer your personal data to such persons, we will take
          all the necessary and in the legal acts indicated measures to ensure
          that your privacy will remain properly secured, including where
          appropriate, signing standard contractual clauses for transfer of
          data. To find out more information regarding appropriate safeguards
          you may contact us via email: hello@nova.health
        </StyledText>
        <StyledHeading>3. HOW LONG WE STORE YOUR DATA?</StyledHeading>
        <StyledText>
          3.1. Your personal data that we process for any purpose or purposes
          shall not be kept for longer than is necessary for that purpose or
          those purposes. In any case it shall be kept for no longer than:
        </StyledText>
        <StyledList>
          <StyledListElement>
            account data will be retained for no longer than 5 (five) years
            following your last update on the account;
          </StyledListElement>
          <StyledListElement>
            service data will be retained for no longer than 5 (five) years
            following the end of provision of services;
          </StyledListElement>
          <StyledListElement>
            messaging data will be retained for no longer than 2 (two) years
            following the provision of consent or, in case, the messaging data
            is being sent to the present clients in order to maintain and
            improve customer relations, for no longer than 2 (two) years
            following the end of provision of the respective services, unless
            you respectively withdraw your consent earlier or object to such
            processing;
          </StyledListElement>
          <StyledListElement>
            correspondence data will be retained for no longer than 6 (six)
            months following the end of such communication.
          </StyledListElement>
        </StyledList>
        <StyledText>
          3.2. In some cases, it is not possible for us to specify in advance
          the periods for which your personal data will be retained. I. e.
          device data will be retained for as much as will be necessary for the
          relevant processing purposes.
        </StyledText>
        <StyledText>
          3.3. After the end of applicable retention period, or upon your
          request personal data is destructed using overwriting or physical
          destruction (when applicable) methods.
        </StyledText>
        <StyledText>
          3.4. Notwithstanding the other provisions of this Section, we may
          retain your personal data where such retention is necessary for
          compliance with a legal obligation to which we are subject, or in
          order to protect your vital interests or the vital interests of
          another natural person.
        </StyledText>
        <StyledHeading>4. MARKETING COMMUNICATION</StyledHeading>
        <StyledText>
          4.1. In case you consent, we will contact you via email or phone to
          inform on what we are up to. Please note, that your consent is not a
          condition of any purchase. Also, if we already have provided services
          to you and you do not object, we will inform you about our Companies
          or our members of our group Companies other products that might
          interest you including other information related to such via email or
          phone, when such were provided to us by you.
        </StyledText>
        <StyledText>
          4.2. We may contact you via email to invite you to review any services
          and/or products you received from us in order to collect your feedback
          and improve our services and products (the “Purpose”). We use an
          external company, Trustpilot A/S (“Trustpilot”), to collect your
          feedback which means that we will share your name, email address and
          reference number with Trustpilot for the Purpose. If you want to read
          more about how Trustpilot process your data, you can find their
          Privacy Policy here.
        </StyledText>
        <StyledText>
          4.3. We may also use such reviews in other promotional material and
          media for our advertising and promotional purposes.
        </StyledText>
        <StyledText>
          4.4. When contacting you by phone as provided in section 4.1. above,
          SMS/text messages from us will be received through your wireless
          provider to the mobile number you provided. SMS/text messages may be
          sent using an automatic telephone dialing system or other technology.
          Message frequency varies. Message and data rates may apply.
        </StyledText>
        <StyledText>
          4.5. You may opt-out of receiving any marketing communications at any
          time. You may do so by choosing the relevant link in any of our
          marketing messages or contacting us via means provided in our website.
          If you are receiving both email and phone marketing communications on
          the grounds provided in section 4.1. above and you wish to opt-out of
          receiving them, you will need to opt out separately by following the
          relevant link in any of our marketing messages or contacting us via
          means provided in our website.
        </StyledText>
        <StyledText>
          4.6. Upon you having fulfilled any of the provided actions we will
          update your profile to ensure that you will not receive our marketing
          communication in the future.
        </StyledText>
        <StyledText>
          4.7. Please be informed that as our business activities consists of a
          network of closely related services, it may take a few days until all
          the systems are updated, thus you may continue to receive marketing
          communication while we are still processing your request.
        </StyledText>
        <StyledText>
          4.8. In any case, the opt-out of the marketing communications will not
          stop you from receiving communication directly related to the
          provision of services.
        </StyledText>
        <StyledHeading>5. YOUR RIGHTS</StyledHeading>

        <StyledText>
          5.1. In this Section, we have summarised the rights that you have
          under data protection laws. Some of the rights are complex thus we
          only provide the main aspects of such rights. Accordingly, you should
          read the relevant laws (first and foremost the General Data Protection
          Regulation (EU) 2016/679) and guidance from the regulatory authorities
          for a full explanation of these rights.
        </StyledText>
        <StyledText>
          5.2. Your principal rights under data protection law are the
          following:
        </StyledText>
        <StyledList>
          <StyledListElement>the right to access data;</StyledListElement>
          <StyledListElement>the right to rectification;</StyledListElement>
          <StyledListElement>
            the right to erasure of your personal data;
          </StyledListElement>
          <StyledListElement>
            the right to restrict processing of your personal data;
          </StyledListElement>
          <StyledListElement>
            the right to object to processing of your personal data;
          </StyledListElement>
          <StyledListElement>the right to data portability;</StyledListElement>
          <StyledListElement>
            the right to complain to a supervisory authority;
          </StyledListElement>
          <StyledListElement>the right to withdraw consent;</StyledListElement>
          <StyledListElement>
            the right to request not to be a subject to a decision based solely
            on automated processing, including profiling.
          </StyledListElement>
        </StyledList>
        <StyledText>
          5.3. The right to access data. You have the right to confirmation as
          to whether or not we process your personal data and, where we do,
          access to the personal data, together with certain additional
          information. That additional information includes details of the
          purposes of the processing, the categories of personal data concerned
          and the recipients of the personal data. Providing the rights and
          freedoms of others are not affected, we will supply to you a copy of
          your personal data. The first copy will be provided free of charge,
          but additional copies may be subject to a reasonable fee.
        </StyledText>
        <StyledText>
          5.4. The right to rectification. You have the right to have any
          inaccurate personal data about you rectified and, taking into account
          the purposes of the processing, to have any incomplete personal data
          about you completed.
        </StyledText>
        <StyledText>
          5.5. In some circumstances you have the right to the erasure of your
          personal data. Those circumstances include when: (i) the personal data
          are no longer necessary in relation to the purposes for which they
          were collected or otherwise processed; (ii) you withdraw consent to
          consent-based processing and there are no other legal basis to process
          data; (iii) you object to the processing under certain rules of
          applicable data protection laws; (iv) the processing is for direct
          marketing purposes; or (v) the personal data have been unlawfully
          processed. However, there are exclusions of the right to erasure. Such
          exclusions include when processing is necessary: (i) for exercising
          the right of freedom of expression and information; (ii) for
          compliance with our legal obligation; or (iii) for the establishment,
          exercise or defence of legal claims. In order to exercise your right
          to be forgotten, please contact us at hello@nova.health. After
          receiving your confirmation, we will delete your personal data to the
          extent legally permitted. Please kindly note, that such deletion is
          irrevocable and does not imply any rights to refunds in any case
        </StyledText>
        <StyledText>
          5.6. In some circumstances you have the right to restrict the
          processing of your personal data. Those circumstances are when: (i)
          you contest the accuracy of the personal data; (ii) processing is
          unlawful but you oppose erasure; (iii) we no longer need the personal
          data for the purposes of our processing, but you require personal data
          for the establishment, exercise or defence of legal claims; and (iv)
          you have objected to processing, pending the verification of that
          objection. Where processing has been restricted on this basis, we may
          continue to store your personal data, however we will only further
          process such data in any other way: (i) with your consent; (ii) for
          the establishment, exercise or defence of legal claims; (iii) for the
          protection of the rights of another person; or (iv) for reasons of
          important public interest.
        </StyledText>
        <StyledText>
          5.7. You have the right to object to our processing of your personal
          data on grounds relating to your particular situation, but only to the
          extent that the legal basis for the processing is that the processing
          is necessary for: the performance of a task carried out in the public
          interest or the purposes of the legitimate interests pursued by us or
          by a third party. If you make such an objection, we will cease to
          process the personal information unless we can demonstrate compelling
          legitimate grounds for the processing which override your interests,
          rights and freedoms, or the processing is for the establishment,
          exercise or defence of legal claims.
        </StyledText>
        <StyledText>
          5.8. You have the right to object to our processing of your personal
          data for direct marketing purposes (including profiling for direct
          marketing purposes). If you make such an objection, we will cease to
          process your personal data for this purpose.
        </StyledText>
        <StyledText>
          5.9. You have the right to object to our processing of your personal
          data for scientific or historical research purposesor statistical
          purposes on grounds relating to your particular situation, unless the
          processing is necessary for the performance of a task carried out for
          reasons of public interest.
        </StyledText>
        <StyledText>
          5.10. The right to data portability. To the extent that the legal
          basis for our processing of your personal data is:
        </StyledText>
        <StyledList>
          <StyledListElement>consent; or</StyledListElement>
          <StyledListElement>
            performance of a contract or steps to be taken at your request prior
            to entering into a contract, necessary to enter into such, you have
            the right to receive your personal data from us in a structured,
            commonly used and machine-readable format. However, this right does
            not apply where it would adversely affect the rights and freedoms of
            others. ;
          </StyledListElement>
        </StyledList>
        <StyledText>
          5.11. If you consider that our processing of your personal information
          infringes data protection laws, you have a legalright to lodge a
          complaint with a supervisory authorityresponsible for data protection.
          You may do so in the EU member state of your habitual residence, your
          place of work or the place of the alleged infringement. Our data
          processing is supervised by State Data Protection Inspectorate of the
          Republic of Lithuania, registered office at L. Sapiegos St. 17,
          LT-10312 Vilnius, www.vdai.lrv.lt
        </StyledText>
        <StyledText>
          5.12. To the extent that the legal basis for our processing of your
          personal information is consent, you have the right to withdraw that
          consent at any time. Withdrawal will not affect the lawfulness of
          processing before the withdrawal.
        </StyledText>
        <StyledText>
          5.13. You have the right to request not to be a subject to a decision
          based solely on automated processing, including profiling, which
          produces legal effects concerning you or similarly significantly
          affects you. However, there are exclusions of this right. Such
          exclusions include when the decision: (i) is necessary for entering
          into, or performance of, a contract between you and us; (ii) s
          authorised by EU or EU member state law to which we are subject to and
          which also lays down suitable measures to safeguard the your rights
          and freedoms and legitimate interests; (iii) is based on your explicit
          consent.
        </StyledText>
        <StyledText>
          5.14. In addition to specific measure provided in this Section or the
          website you may also exercise any of the rights indicated herein by
          contacting us by email: hello@nova.health. We undertake to respond to
          such inquiries within 1 month after it is received. This response time
          may be extended by 2 months for complex or multiple requests. In case
          of such extension, we will inform you additionally.
        </StyledText>
        <StyledHeading>6. ABOUT COOKIES</StyledHeading>

        <StyledText>
          6.1. Cookies are small textual files containing identifier that is
          sent by a web server to your web browser and is stored by the browser.
          The identifier is then sent back to the server each time the browser
          requests a page from the server.
        </StyledText>
        <StyledText>
          6.2. We will ask you to consent to our use of cookies in accordance
          with the terms of this notice when you first visit our website.
        </StyledText>
        <StyledText>
          6.3. Cookies do not typically contain any information that personally
          identifies a user, but personal information that we store about you
          may be linked to the information stored in and obtained from cookies.
        </StyledText>
        <StyledHeading>7. COOKIES THAT WE USE</StyledHeading>

        <StyledText>
          In the website we use cookies of three main types, for the following
          purposes:
        </StyledText>
        <StyledList>
          <StyledListElement>
            Required cookies – used to ensure proper performance of the website,
            security of customers and their data, provision of high-quality
            services;
          </StyledListElement>
          <StyledListElement>
            Functional cookies – used to enhance the website user experience,
            analyse the use of the system and in accordance to such improve the
            provision of services;
          </StyledListElement>
          <StyledListElement>
            Advertising cookies – used to observer user online behaviour and
            optimize marketing campaigns according to such information.
          </StyledListElement>
        </StyledList>
        <StyledHeading>8. COOKIES USED BY OUR SERVICE PROVIDERS</StyledHeading>
        <StyledText>
          8.1. Our service providers use cookies and those cookies may be stored
          on your computer when you visit our website.
        </StyledText>
        <StyledText>8.2. We may use:</StyledText>
        <StyledList>
          <StyledListElement>
            Google Analytics cookies to observe our website traffic. Cookies
            used for this purpose help us detect website errors as well as
            measure website bandwidth. You can view the privacy policy of Google
            Analytics here;
          </StyledListElement>
          <StyledListElement>
            Youtube cookies to display in our website content uploaded in
            Youtube. Cookies used for this purpose help us maintain integrity,
            create informative and dynamic website. You can view the privacy
            policy of Youtube here;
          </StyledListElement>
          <StyledListElement>
            Twitter cookies to display in our website content posted in Twitter.
            Cookies used for this purpose help us maintain integrity, create
            informative and dynamic website. You can view the privacy policy of
            Twitter here;
          </StyledListElement>
          <StyledListElement>
            Google Maps cookies to, if the user permits, determine users
            location. Cookies used for this purpose help us adapt website
            settings in accordance to user’s location and improve user
            experience in our website. You can view the privacy policy of Google
            Maps here;
          </StyledListElement>
          <StyledListElement>
            Doubleclick cookies to control the display of ads to our users.
            Cookies used for this purpose help us distinguish users that already
            use our services and reduce or stop displaying our ads to such
            users. You can view the privacy policy of Doubleclick here;
          </StyledListElement>
          <StyledListElement>
            Facebook cookies to manage the display of ads to our users. Cookies
            used for this purpose help us distinguish users that already use our
            services and reduce or stop displaying our ads to such users. You
            can view the privacy policy of Facebook here;
          </StyledListElement>
          <StyledListElement>
            Google Tag Manager cookies to control advertising cookies. Cookies
            used for this purpose help us properly distribute ads to users. You
            can view the privacy policy of Google Tag Manager here;
          </StyledListElement>
          <StyledListElement>
            Hotjar cookies to observe how users use our website. Cookies used
            for this purpose help us observe the performance of the website and
            analyse how we can improve our website. You can view the privacy
            policy of Hotjar here;
          </StyledListElement>
          <StyledListElement>
            Visual Website Optimiser cookies cookies that are used for
            collecting information on how visitors interact with the pages on
            website. You can view the privacy policy of Visual Website Optimiser
            here;
          </StyledListElement>
        </StyledList>
        <StyledHeading>9. HOW CAN YOU MANAGE COOKIES?</StyledHeading>
        <StyledText>
          9.1. Most browsers allow you to refuse to accept cookies and to delete
          cookies. The methods for doing so vary from browser to browser, and
          from version to version. You can however obtain up-to-date information
          about blocking and deleting cookies via information provided in the
          relevant browser website, for example Chrome; Firefox; Internet
          Explorer;Safari.
        </StyledText>
        <StyledText>
          9.2. Blocking all cookies will have a negative impact upon the
          usability of many websites.
        </StyledText>
        <StyledText>
          9.3. If you block cookies, you will not be able to use all the
          features on our website.
        </StyledText>
        <StyledHeading>10. THIRD PARTY WEBSITES</StyledHeading>
        <StyledText>
          In the website you may find links to and from partner sites,
          information sources and related party websites. Please take note that
          such third party website that you will visit by clicking on links have
          their own privacy policies and we take no responsibility regarding
          such privacy policies. We recommend familiarising with privacy
          policies of such websites before providing any personal data to such.
        </StyledText>
        <StyledHeading>11. CHILDREN PERSONAL DATA</StyledHeading>
        <StyledText>
          11.1. Our website and services are targeted at persons over the age of
          18.
        </StyledText>
        <StyledText>
          11.2. If we have reason to believe that we hold personal data of a
          person under that age in our databases without having consent from the
          parent rights holder, we will delete that personal data.
        </StyledText>
        <StyledHeading>12. CALIFORNIA PRIVACY ADDENDUM</StyledHeading>
        <StyledText>
          If you are a California consumer or resident, in addition to the
          information provided in this privacy policy, you may have the
          additional rights and information provided to you under the California
          Consumer Privacy Act:
        </StyledText>
        <StyledList>
          <StyledListElement>
            We do not knowingly sell personal information nor share it with
            third parties for direct marketing purposes. However, if we do so in
            the future, you will be notified and have the right to opt-out of
            the “sale” of personal information;
          </StyledListElement>
          <StyledListElement>
            We will retain, use, or disclose personal information we collect or
            processes on your behalf, only for the purposes described in this
            privacy policy, and will notify you if this changes.
          </StyledListElement>
          <StyledListElement>
            You have the right to not be subject to discrimination if you
            exercise any of your rights.
          </StyledListElement>
        </StyledList>
        <StyledText>
          We do not currently recognize or respond to browser-initiated Do Not
          Track signals. Instructions to enable for the following browsers are
          here: Chrome, Firefox, IE, Edge, Safari, Opera.
        </StyledText>
        <StyledHeading>13. Updating your data</StyledHeading>
        <StyledText>
          Please let us know if the personal information that we hold about you
          needs to be corrected or updated.
        </StyledText>
        <StyledHeading>14. CHANGES TO THE NOTICE</StyledHeading>
        <StyledText>
          Any changes to this notice will be published in the website and, in
          case of material changes, we may inform you about such via email or by
          other means which we will deem the most fitting in a particular case.
        </StyledText>
        {/* Insert more sections as needed */}
        <StyledText>Last Updated: January 1st, 2024</StyledText>
      </Wrapper>
    </Container>
    <Footer isMobileApp={false} />
  </>
);

export default Privacy;

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1110px;
`;

const MainContainer = styled.div`
  width: 100vw;
  background-size: cover;
  padding-top: 69px;
`;

const StyledList = styled.ol`
  counter-reset: section;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 1rem;
`;

const StyledListElement = styled.li`
  &::before {
    content: '(' counter(section, lower-alpha) ') ';
    counter-increment: section;
  }
`;

const StyledTitle = styled(Text)`
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 47px;
  @media ${tablet} {
    font-size: 2rem;
  }
`;

const Container = styled.div`
  padding: 48px 16px 40px;
`;

const StyledText = styled(Text)`
  margin-bottom: 1.375rem;
  color: #28293d;
  font-weight: 300;
  letter-spacing: -0.024em;
`;

const StyledHeading = styled(Text)`
  font-weight: 600;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
`;
